import React, { BaseSyntheticEvent } from 'react';
import { useMutate } from 'restful-react';
import { TextInput } from '../../FormComponents/TextInput/TextInput';
import { BaseModal } from '../BaseModal/BaseModal';
import './StreamDestinationModal.scss';
import { Button } from '../../FormComponents/Buttons/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Consumer, ConsumerCreationDto } from '../../../types/Consumer';
import useProjectDetails from '../../../hooks/useProjectDetails';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { captionLanguages, demoCaptionLanguages } from '../../../common/constants';
import { Dropdown } from '../../FormComponents/Dropdown/Dropdown';
import { ValidationError } from '../../FormComponents/ValidationError/ValidationError';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

type Props = {
  isOpen: boolean;
  contentLabel: string;
  selectedStream: ConsumerCreationDto;
  handleCloseModal: () => void;
};

type CreateStreamDestinationFormData = {
  ingestKey: string;
  ingestUrl: string;
  name: string;
  languageId: string;
};

type DeleteStreamDestinationFormData = {
  id: string;
};

export const StreamDestinationModal = (props: Props) => {
  const { t } = useTranslation();
  const { projectDetails } = useProjectDetails();

  const defaultValues: CreateStreamDestinationFormData = {
    ingestKey: props.selectedStream.ingestKey,
    ingestUrl: props.selectedStream.ingestUrl,
    name: props.selectedStream.name,
    languageId: props.selectedStream.languageId,
  };

  const { control, errors, handleSubmit } = useForm<CreateStreamDestinationFormData>({
    defaultValues,
  });

  const removeConsumerToProjectDetails = (consumerId: string) => {
    const itemIndex = projectDetails?.consumers?.map((item) => item.id).indexOf(consumerId);

    if (itemIndex != null && itemIndex !== -1) {
      projectDetails?.consumers?.splice(itemIndex, 1);
    }
  };

  const getCaptionLanguages = () => {
    if (projectDetails?.activeLayout?.hasDemoCaptions && props.selectedStream.languageId !== '') {
      if (
        demoCaptionLanguages.filter((x) => x.value === props.selectedStream.languageId).length === 0
      ) {
        return [
          ...demoCaptionLanguages,
          captionLanguages.filter((x) => x.value === props.selectedStream.languageId)[0],
        ];
      }

      return demoCaptionLanguages;
    }

    return projectDetails?.activeLayout?.hasDemoCaptions ? demoCaptionLanguages : captionLanguages;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessfulCreate = (body: any, data: any) => {
    if (data.statusCode === 200) {
      const newEntry: Consumer = {
        id: data.result,
        ingestKey: body.ingestKey,
        ingestUrl: body.ingestUrl,
        languageId: body.languageId,
        name: body.name,
      };
      projectDetails?.consumers?.push(newEntry);
      props.handleCloseModal();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessfulDelete = (body: any, data: any) => {
    if (data.statusCode === 200) {
      removeConsumerToProjectDetails(body.id);
      props.handleCloseModal();
    }
  };

  const {
    mutate: createStreamDestination,
    loading: loadingCreate,
    error: errorCreate,
  } = useMutate<CreateStreamDestinationFormData>({
    verb: 'POST',
    path: '/consumers',
    onMutate: onSuccessfulCreate,
  });

  const {
    mutate: deleteStreamDestination,
    loading: loadingDelete,
    error: errorDelete,
  } = useMutate<DeleteStreamDestinationFormData>({
    verb: 'DELETE',
    path: '/consumers',
    onMutate: onSuccessfulDelete,
  });

  const onSubmit: SubmitHandler<CreateStreamDestinationFormData> = (
    data: CreateStreamDestinationFormData,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault();
    data.languageId = data.languageId ?? 'en';
    createStreamDestination({ ...data, layoutId: props.selectedStream.layoutId });
  };

  const onDelete = (data: DeleteStreamDestinationFormData) => {
    deleteStreamDestination(data);
  };

  const isValidDestinationUrl = (ingestUrl: string) => {
    if (
      ingestUrl.toLowerCase().startsWith('rtmps://') ||
      ingestUrl.toLowerCase().startsWith('rtmp://')
    ) {
      return true;
    }
    return false;
  };

  if (errorCreate || errorDelete) {
    return <ErrorMessage message={t('error-messages.generic-error')} />;
  }

  return (
    <BaseModal
      isOpen={props.isOpen}
      contentLabel={props.contentLabel}
      handleCloseModal={props.handleCloseModal}
      ariaHideApp={true}
      title="producer.control-panel.stream-destinations.modal-title"
    >
      <form className="stream-destination-modal" onSubmit={handleSubmit(onSubmit)}>
        <ValidationError
          errorType={errors.ingestUrl}
          errorMessageKey={errors.ingestUrl?.message ?? ''}
        />
        <Controller
          name="ingestUrl"
          control={control}
          rules={{
            required: true,
            maxLength: 250,
            validate: (value) => isValidDestinationUrl(value),
          }}
          render={({ onChange, value }) => (
            <>
              <label>{t('producer.control-panel.stream-destinations.stream-url-title')}</label>
              <TextInput
                placeholder="producer.control-panel.stream-destinations.stream-url-placeholder"
                value={value}
                onChange={onChange}
                onAfterChange={() => {
                  let url: URL;

                  try {
                    url = new URL(
                      control
                        .getValues()
                        .ingestUrl.replace('rtmps', 'https')
                        .replace('rtmp', 'https'),
                    );
                  } catch {
                    return;
                  }

                  const overRides = ['YouTube', 'Vimeo', 'FACEBOOK', 'LINKEDIN'];

                  let name = url.hostname.toLocaleLowerCase();

                  for (const index in overRides) {
                    if (name.indexOf(overRides[index].toLocaleLowerCase()) > -1) {
                      name = overRides[index];
                    }
                  }

                  control.setValue('name', name, { shouldValidate: true, shouldDirty: true });
                }}
                errorType={errors.ingestUrl}
                errorMessageKey={
                  value.length <= 250
                    ? 'producer.control-panel.stream-destinations.stream-url-missing-value'
                    : 'producer.control-panel.stream-destinations.stream-url-max-length'
                }
                readOnly={props.selectedStream.id !== ''}
              />
            </>
          )}
        />
        <Controller
          name="ingestKey"
          control={control}
          rules={{ required: true, maxLength: 150 }}
          render={({ onChange, value }) => (
            <>
              <label>{t('producer.control-panel.stream-destinations.stream-key-title')}</label>
              <TextInput
                placeholder="producer.control-panel.stream-destinations.stream-key-placeholder"
                value={value}
                onChange={onChange}
                onAfterChange={null}
                errorType={errors.ingestKey}
                errorMessageKey={
                  value.length <= 150
                    ? 'producer.control-panel.stream-destinations.stream-key-missing-value'
                    : 'producer.control-panel.stream-destinations.stream-key-max-length'
                }
                readOnly={props.selectedStream.id !== ''}
              />
            </>
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: true, maxLength: 150 }}
          render={({ onChange, value }) => (
            <>
              <label>{t('producer.control-panel.stream-destinations.stream-name-title')}</label>
              <TextInput
                placeholder="producer.control-panel.stream-destinations.stream-name-placeholder"
                value={value}
                onChange={onChange}
                onAfterChange={null}
                errorType={errors.name}
                errorMessageKey={
                  value.length <= 150
                    ? 'producer.control-panel.stream-destinations.stream-name-missing-value'
                    : 'producer.control-panel.stream-destinations.stream-name-max-length'
                }
                readOnly={props.selectedStream.id !== ''}
              />
            </>
          )}
        />
        {projectDetails?.activeLayout?.hasCaption && (
          <Controller
            control={control}
            name="languageId"
            className="mb-3"
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <>
                {props.selectedStream.id !== '' && (
                  <p className="caption-description">
                    {t(`producer.control-panel.stream-destinations.caption-language`)}
                  </p>
                )}

                {props.selectedStream.id === '' && (
                  <p className="caption-description">
                    {t(
                      `producer.control-panel.stream-destinations.caption-language-${
                        projectDetails?.activeLayout?.hasDemoCaptions ? 'demo-' : ''
                      }title`,
                    )}
                  </p>
                )}

                <Dropdown
                  value={value || null}
                  options={getCaptionLanguages()}
                  onChange={(data) => onChange(data?.value)}
                  placeholderKey="producer.control-panel.stream-destinations.stream-language-placeholder"
                  errorType={errors.languageId}
                  errorMessageKey="producer.control-panel.stream-destinations.stream-language-missing-value"
                  readonly={
                    props.selectedStream.id !== '' ||
                    projectDetails?.broadcastStartedOnUtc !== undefined
                  }
                />
              </>
            )}
          />
        )}
        {projectDetails?.broadcastStartedOnUtc && props.selectedStream.id === '' && (
          <Controller
            control={control}
            name="languageId"
            className="mb-3"
            rules={{ required: true }}
            render={() => (
              <>
                <p className="caption-description">
                  {t(`producer.control-panel.stream-destinations.caption-language-loop-started`)}
                </p>
              </>
            )}
          />
        )}
        <br />
        {props.selectedStream.id === '' ? (
          <>
            {!loadingCreate ? (
              <Button
                translationKey="producer.control-panel.stream-destinations.set"
                variant="primary"
                color="blue"
                onClick={() => null}
                type="submit"
                disabled={loadingCreate}
              />
            ) : (
              <LoadingSpinner />
            )}
          </>
        ) : null}
      </form>
      {props.selectedStream.id !== '' ? (
        <>
          {!loadingDelete ? (
            <Button
              translationKey="producer.control-panel.stream-destinations.delete"
              variant="secondary"
              color="blue"
              onClick={() => onDelete({ id: props.selectedStream.id })}
              type={undefined}
              disabled={loadingDelete}
            />
          ) : (
            <LoadingSpinner />
          )}
        </>
      ) : null}
    </BaseModal>
  );
};
