import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import env from '../../../Env';
import { ProjectDetail } from '../../../types/ProjectDetail';
import MainSectionComplete from '../MainSectionComplete/MainSectionComplete';
import MainSectionLiveVideo from '../MainSectionLiveVideo/MainSectionLiveVideo';
import { PopupModal } from '../../Modals/PopupModal/PopupModal';
import './MainSectionVideo.scss';
import metadata from '../../../metadata.json';
import { UserTypes } from '../../../types/ProjectUser';

type MainSectionVideoProps = {
  projectDetails: ProjectDetail | null;
  triggerRefreshLiveVideo: boolean;
  isAwaitingApiSaveToComplete: boolean;
  userType: UserTypes | undefined;
};

const MainSectionVideo = ({
  projectDetails,
  triggerRefreshLiveVideo,
  isAwaitingApiSaveToComplete,
  userType,
}: MainSectionVideoProps) => {
  const { t } = useTranslation();
  const [currentResolution, setCurrentResolution] = useState<number>(0);
  const [showPaymentFailedModal, setShowPaymentFailedModal] = useState<boolean>(false);
  const [closedPaymentFailedModal, setClosedPaymentFailedModal] = useState<boolean>(false);

  const isPaymentFailed = useCallback(() => {
    if (projectDetails?.paymentStatus === 'requires_action') {
    }

    if (
      projectDetails?.paymentStatus === '' ||
      projectDetails?.paymentStatus === 'succeeded' ||
      !projectDetails?.paymentStatus
    ) {
      setShowPaymentFailedModal(false);
      return false;
    }

    setShowPaymentFailedModal(true);
    return true;
  }, [projectDetails]);

  const buildIframeSrc = () => {
    if (projectDetails != null && projectDetails.id && projectDetails?.activeLayout?.id) {
      return `${env.baseUrl}/ProxyView/Overlay/${projectDetails.id}/${projectDetails?.activeLayout?.id}?language=en&version=${metadata?.build}`;
    }

    return '';
  };

  const isCaptionerPreview = (
    projectDetails: ProjectDetail | null,
    userType: UserTypes | undefined,
  ): boolean => !projectDetails?.broadcastStartedOnUtc && userType === UserTypes.CAPTIONER;

  useEffect(() => {
    if (
      projectDetails &&
      projectDetails.resolution &&
      projectDetails.resolution !== currentResolution
    ) {
      setCurrentResolution(projectDetails.resolution);
    }
  }, [projectDetails, currentResolution]);

  useEffect(() => {
    isPaymentFailed();
  }, [projectDetails, isPaymentFailed]);

  return (
    <>
      <PopupModal
        isOpen={showPaymentFailedModal && !closedPaymentFailedModal}
        handleCloseModal={() => setClosedPaymentFailedModal(true)}
        title="producer.payment-failed.title"
        content={t('producer.payment-failed.content')}
      />
      <div className="main-section-video">
        <div className="main-section-container">
          {projectDetails?.broadcastEndedOnUtc || projectDetails?.deletedOnUtc ? (
            <MainSectionComplete />
          ) : (
            <>
              {projectDetails?.broadcastStartedOnUtc || projectDetails?.previewStartedOnUtc ? (
                <MainSectionLiveVideo
                  projectDetails={projectDetails}
                  triggerRefreshLiveVideo={triggerRefreshLiveVideo}
                />
              ) : (
                <iframe
                  scrolling="no"
                  src={buildIframeSrc()}
                  title="Broadcast Stream"
                  className={`main-section-container__iframe-overlay ${
                    projectDetails?.resolution.toString() === '1080' ? 'is-fhd' : 'is-hd'
                  }`}
                ></iframe>
              )}
            </>
          )}
        </div>
        {projectDetails?.activeLayout?.hasClosedCaptions &&
          projectDetails?.activeLayout?.hasCaption && (
            <p className="main-section-video__cc-message">
              {t('producer.control-panel.captioning.cc-notice')}
            </p>
          )}
        {isCaptionerPreview(projectDetails, userType) && (
          <div className="captioner-preview">{t('project-user.captioner.captioning-preview')}</div>
        )}
        {isAwaitingApiSaveToComplete && (
          <p className="main-section-video__layout-updating-message">
            {t('producer.control-panel.layout-updating')}
          </p>
        )}
      </div>
    </>
  );
};

export default MainSectionVideo;
