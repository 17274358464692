import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../SharedControlPanel.scss';
import './CaptioningPanel.scss';
import env from '../../../../../Env';

import {
  // CaptionFontSizes,
  CaptionStyle,
  CaptionThemes,
  Layout,
  // StreamSizes,
} from '../../../../../types/Layout';
import {
  ProjectUser,
  ProjectUserCreationDto,
  ProjectUsers,
  UserTypes,
} from '../../../../../types/ProjectUser';
// import FullScreenStreamIcon from '../../../../../images/fullscreen-stream.png';
// import ScaledStreamIcon from '../../../../../images/scaled-stream.png';
import TickIcon from '../../../../../images/tick-destination-icon.png';
import TextThreeLinesIcon from '../../../../../images/text-lines-three-icon.png';
import TextTwoLinesIcon from '../../../../../images/text-lines-two-icon.png';
import ToggleButton from '../../Widgets/ToggleButton';
import { InviteProjectUserModal } from '../../../../Modals/InviteProjectUserModal/InviteProjectUserModal';
import useProjectDetails from '../../../../../hooks/useProjectDetails';
import CopyIcon from '../../../../../images/copy-icon.png';
import PageIcon from '../../../../../images/chat-panel-Icon.png';
import DockedIcon from '../../../../../images/caption-docked-icon.png';
import UndockedIcon from '../../../../../images/caption-undocked-icon.png';
import {
  captionLanguages,
  // demoCaptionLanguages,
  fontFamilies,
} from '../../../../../common/constants';
import { Dropdown } from '../../../../FormComponents/Dropdown/Dropdown';
import { PopupModal } from '../../../../Modals/PopupModal/PopupModal';

type CaptioningPanelProps = {
  selectedLayout: Layout | undefined | null;
  projectUsers: ProjectUsers;
  isReadOnly: boolean;
  updateAssetDetails: (hasCaptions: boolean) => void;
};

const CaptioningPanel = ({
  selectedLayout,
  projectUsers,
  isReadOnly,
  updateAssetDetails,
}: CaptioningPanelProps) => {
  const { t } = useTranslation();
  const {
    projectDetails,
    updateActiveLayout,
    updateActiveLayoutWithMultiple,
  } = useProjectDetails();
  const maxNumberOfCaptioners = 4;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showRetrievingStreamTextName, setShowRetrievingStreamTextName] = useState(false);
  const [showCaptionOverlayInstructions, setShowCaptionOverlayInstructions] = useState(false);
  // const [nonDemoLanguageWarning, setNonDemoLanguageWarning] = useState(false);
  const [selectedProjectUser, setSelectedProjectUser] = useState<ProjectUserCreationDto>({
    id: '',
    name: '',
    emailAddress: '',
    projectId: '',
    userType: UserTypes.CAPTIONER,
    accessToken: '',
  });

  const getStreamTextQrCode = useCallback((): string => {
    if (projectDetails?.streamTextEventName === '') {
      return '';
    }

    return `${env.blobStorageUrl}/captioning/${projectDetails?.id}.png?hash=${Math.floor(
      Math.random() * 1000000,
    )}`;
  }, [projectDetails]);

  useEffect(() => {
    if (
      selectedLayout?.hasCaption &&
      !projectDetails?.streamTextEventName &&
      !projectDetails?.activeLayout?.hasDemoCaptions
    ) {
      setShowRetrievingStreamTextName(true);
    } else {
      setShowRetrievingStreamTextName(false);
    }
  }, [selectedLayout, projectDetails]);

  const isMAC = (): boolean => navigator.platform.toUpperCase().indexOf('MAC') >= 0;

  // if (nonDemoLanguageWarning) {
  //   const contentKey = 'producer.control-panel.captioning.demo-captions.warning.content';
  //   const supportedLanguagesKey =
  //     'producer.control-panel.captioning.demo-captions.warning.supported-languages';

  //   const supportedLanguages: string = demoCaptionLanguages.map((x) => x.text).join('<br/>');

  //   const content = `${t(contentKey)}<br/><h2 class="ReactModal__Content__Title">${t(
  //     supportedLanguagesKey,
  //   )}</h2><br/>${supportedLanguages}`;

  //   return (
  //     <PopupModal
  //       isOpen={true}
  //       handleCloseModal={() => setNonDemoLanguageWarning(false)}
  //       title="producer.control-panel.captioning.demo-captions.warning.title"
  //       content={content}
  //     />
  //   );
  // }

  if (showCaptionOverlayInstructions) {
    return (
      <PopupModal
        isOpen={true}
        handleCloseModal={() => setShowCaptionOverlayInstructions(false)}
        title={`producer.control-panel.captioning.caption-ovarlay-model-title-${
          isMAC() ? 'mac' : 'windows'
        }`}
        content={t(
          `producer.control-panel.captioning.caption-ovarlay-model-content-${
            isMAC() ? 'mac' : 'windows'
          }`,
        )}
      />
    );
  }

  const copyToClipboard = (copyValue: string | undefined) => {
    if (copyValue) {
      navigator.clipboard.writeText(copyValue);
    }
  };

  const openInviteProjectUserModal = (name = '', emailAddress = '', id = '', accessToken = '') => {
    if (!selectedLayout?.hasCaption) {
      updateActiveLayout('hasCaption', true);
    }

    setSelectedProjectUser({
      id: id,
      name: name,
      emailAddress: emailAddress,
      projectId: projectDetails?.id ?? '',
      userType: UserTypes.CAPTIONER,
      accessToken: accessToken,
    });
    setIsModalOpen(true);
  };

  const getCaptionersFromProjectUsers = (): ProjectUsers => {
    if (!projectUsers || projectUsers.length === 0) {
      return [];
    }

    const users = projectUsers.filter(function (obj) {
      return obj.userType === UserTypes.CAPTIONER;
    });

    return users || [];
  };

  // const hasNonDemoLanguageDestination = () => {
  //   if (!projectDetails?.consumers) {
  //     return false;
  //   }

  //   for (const consumer of projectDetails?.consumers) {
  //     if (demoCaptionLanguages.find((x) => x.value === consumer.languageId) === undefined) {
  //       return true;
  //     }
  //   }

  //   return false;
  // };

  // const demoCaptionsNote = (): string => {
  //   const notes: Array<string> = [];
  //   notes.push(t('producer.control-panel.captioning.demo-captions.note'));
  //   notes.push(t('producer.control-panel.captioning.demo-captions.multi-lingual'));

  //   return notes.join(' ');
  // };

  const createAddCaptionersButtons = () => {
    if (isReadOnly) {
      return null;
    }
    const addCaptionersButtons = [];
    const numberOfButtonsToCreate = maxNumberOfCaptioners - getCaptionersFromProjectUsers().length;
    for (let index = 0; index < numberOfButtonsToCreate; index++) {
      addCaptionersButtons.push(
        <button
          key={index}
          className="control-panel-section-box control-panel-section-box-new"
          onClick={() => openInviteProjectUserModal()}
          disabled={isReadOnly}
        >
          <span className="control-panel-section-box-text">
            + {t('producer.control-panel.captioning.invite-captioner-button')}
          </span>
        </button>,
      );
    }
    return addCaptionersButtons;
  };

  const canChangeCaptionType = () => {
    if (!projectDetails) {
      return false;
    }

    if (projectDetails.consumers?.length === 0) {
      return true;
    }

    const languages = Array.from(new Set(projectDetails.consumers?.map((item) => item.languageId)));

    return projectDetails.activeLayout?.hasClosedCaptions || languages.length === 1;
  };

  const getStreamTextEventName = (): string => {
    if (projectDetails?.activeLayout?.hasDemoCaptions) {
      return 'ALDemoCaptions';
    }

    if (projectDetails?.streamTextEventName) {
      return projectDetails?.streamTextEventName;
    }

    if (showRetrievingStreamTextName) {
      return t('producer.control-panel.captioning.stream-text-title-retrieving-message');
    }

    return t('producer.control-panel.captioning.no-stream-text-event');
  };

  const getStreamCastUrl = (): string => {
    if (isMAC()) {
      return 'https://www.dropbox.com/s/yqvvdkvkkxfc8ad/StreamCast.dmg?dl=1';
    }

    return 'https://www.dropbox.com/s/knlxunfv5ylgxo7/StreamCast.exe?dl=1';
  };

  return (
    <div className="captioning-panel">
      <div className="captioning-section">
        {getCaptionersFromProjectUsers().map((projectUser: ProjectUser) => (
          <button
            key={projectUser?.id}
            className="control-panel-section-box"
            onClick={() =>
              openInviteProjectUserModal(
                projectUser?.name,
                projectUser?.emailAddress,
                projectUser?.id,
                projectUser?.accessToken,
              )
            }
            disabled={isReadOnly}
          >
            <span className="control-panel-section-box-text">{projectUser?.name}</span>
            <img src={TickIcon} alt="tick" className="control-panel-section-box-tick" />
          </button>
        ))}
        {createAddCaptionersButtons()}
        <div className="captioning-panel-section-footer">
          <p>
            <span className="captioning-panel-section-footer-title">
              {t('producer.control-panel.captioning.note')}{' '}
            </span>
            <span className="captioning-panel-section-footer-text">
              {t('producer.control-panel.captioning.note-text')}
            </span>
          </p>
        </div>
      </div>

      <>
        <hr className="control-panel-section-divider" />
        <div className="captioning-section captioning-settings-section">
          <div className="captioning-settings-position captioning-settings-position-block-with-title">
            <div className="captioning-settings-open-closed-captions-section">
              <div className="captioning-settings-title">
                {t('producer.control-panel.captioning.caption-type')}
              </div>
              <div className="level level-left">
                <ToggleButton
                  leftText={t('producer.control-panel.captioning.open-captions')}
                  leftValue={false}
                  leftButtonClassName={`captioning-settings-caption-types-button ${
                    !selectedLayout?.hasClosedCaptions
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  leftContentClassName="captioning-settings-caption-types-button-text"
                  rightText={t('producer.control-panel.captioning.closed-captions')}
                  rightValue={true}
                  rightButtonClassName={`captioning-settings-caption-types-button ${
                    selectedLayout?.hasClosedCaptions
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  rightContentClassName="captioning-settings-caption-types-button-text"
                  footerText={''}
                  isReadOnly={false}
                  onSelection={(selectedSide) => {
                    if (selectedSide && projectDetails?.activeLayout?.hasDemoCaptions) {
                      selectedSide = !selectedSide;
                      return;
                    }

                    const properties: Record<string, string | number | boolean> = {
                      hasCaption: true,
                      hasDemoCaptions: false,
                      hasClosedCaptions: selectedSide,
                    };

                    updateActiveLayoutWithMultiple(properties);
                  }}
                  leftAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.open-captions',
                  )}
                  rightAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.closed-captions',
                  )}
                />
              </div>
            </div>
          </div>
          {!canChangeCaptionType() && (
            <div className="captioning-panel-section-footer">
              <p>
                <span className="captioning-panel-section-footer-title">
                  {t('producer.control-panel.captioning.note')}{' '}
                </span>
                <span className="captioning-panel-section-footer-text">
                  {t('producer.control-panel.captioning.accessibility.too-many-languages-warning')}
                </span>
              </p>
            </div>
          )}
          <div className="captioning-panel-section-footer">
            <p>
              <span className="captioning-panel-section-footer-title">
                {t('producer.control-panel.captioning.note')}{' '}
              </span>
              <span className="captioning-panel-section-footer-text">
                {t('producer.control-panel.captioning.accessibility.change-once-live-warning')}
              </span>
            </p>
          </div>
        </div>
        <hr className="control-panel-section-divider" />
      </>

      {/* <div className="captioning-settings-section level has-no-margin-bottom">
        <div className="captioning-settings-title">
          <p className="captioning-settings-title-text">
            {t('producer.control-panel.captioning.demo-captions.title')}
          </p>
        </div>
      </div> */}
      {/* <div className="level level-left has-no-margin-bottom">
        <ToggleButton
          leftText={t('producer.common.on')}
          leftValue={true}
          leftButtonClassName={`signing-settings-logo-button ${
            selectedLayout?.hasDemoCaptions ? 'control-panel-button-selected-button' : null
          }`}
          leftContentClassName="signing-settings-logo-button-text"
          rightText={t('producer.common.off')}
          rightValue={false}
          rightButtonClassName={`signing-settings-logo-button ${
            !selectedLayout?.hasDemoCaptions ? 'control-panel-button-selected-button' : null
          }`}
          rightContentClassName="signing-settings-logo-button-text"
          footerText={''}
          isReadOnly={false}
          onSelection={(selectedSide) => {
            if (selectedSide && hasNonDemoLanguageDestination()) {
              setNonDemoLanguageWarning(true);
            }

            if (selectedSide) {
              const properties: Record<string, string | number | boolean> = {
                hasCaption: true,
                hasDemoCaptions: true,
                hasClosedCaptions: false,
                hasAiCaptions: false,
              };

              updateActiveLayoutWithMultiple(properties);

              return;
            }

            updateActiveLayout('hasDemoCaptions', false);
          }}
          leftAccessibilityName={t('producer.common.on')}
          rightAccessibilityName={t('producer.common.off')}
        />
      </div>
      <div className="captioning-panel-section-footer has-no-margin-top has-margin-bottom">
        <p>
          <span className="captioning-panel-section-footer-title">
            {t('producer.control-panel.captioning.note')}{' '}
          </span>
          <span className="captioning-panel-section-footer-text">{demoCaptionsNote()}</span>
        </p>
      </div> */}

      <hr className="control-panel-section-divider" />

      <div className="captioning-settings-section level has-no-margin-bottom">
        <div className="captioning-settings-title">
          <p className="captioning-settings-title-text">
            {t('producer.control-panel.captioning.ai-captions.title')}
          </p>
        </div>
      </div>
      <div className="level level-left has-no-margin-bottom">
        <ToggleButton
          leftText={t('producer.common.on')}
          leftValue={true}
          leftButtonClassName={`signing-settings-logo-button ${
            selectedLayout?.hasAiCaptions ? 'control-panel-button-selected-button' : null
          }`}
          leftContentClassName="signing-settings-logo-button-text"
          rightText={t('producer.common.off')}
          rightValue={false}
          rightButtonClassName={`signing-settings-logo-button ${
            !selectedLayout?.hasAiCaptions ? 'control-panel-button-selected-button' : null
          }`}
          rightContentClassName="signing-settings-logo-button-text"
          footerText={''}
          isReadOnly={false}
          onSelection={(selectedSide) => {
            if (selectedSide) {
              const properties: Record<string, string | number | boolean> = {
                hasCaption: true,
                hasDemoCaptions: false,
                hasAiCaptions: true,
              };

              updateActiveLayoutWithMultiple(properties);

              return;
            }

            updateActiveLayout('hasAiCaptions', false);
          }}
          leftAccessibilityName={t('producer.common.on')}
          rightAccessibilityName={t('producer.common.off')}
        />
      </div>

      <hr className="control-panel-section-divider" />

      <div className="captioning-settings-section">
        <div className="captioning-settings-position">
          <div className="captioning-settings-title">
            {t('producer.control-panel.captioning.source-caption-title')}
          </div>
          <div className="level level-left">
            <Dropdown
              value={selectedLayout?.sourceCaptionLanguage || selectedLayout?.language || 'en'}
              options={captionLanguages}
              onChange={(data) => updateActiveLayout('sourceCaptionLanguage', data.value)}
              placeholderKey="producer.control-panel.captioning.source-caption-placeholder"
              errorType={undefined}
              errorMessageKey="producer.control-panel.captioning.source-caption-missing-value"
              readonly={false}
            />
          </div>
        </div>
      </div>

      {!projectDetails?.activeLayout?.hasClosedCaptions && (
        <>
          <hr className="control-panel-section-divider" />
          <div className="captioning-settings-section">
            <div className="captioning-settings-position captioning-settings-position-block-with-title">
              <div className="captioning-settings-title">
                {t('producer.control-panel.captioning.caption-style-title')}
              </div>
              <div className="level level-left">
                <ToggleButton
                  leftImage={DockedIcon}
                  leftButtonClassName={`${
                    selectedLayout?.captionStyle === CaptionStyle.DOCKED
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  leftValue={CaptionStyle.DOCKED}
                  rightValue={CaptionStyle.UNDOCKED}
                  rightImage={UndockedIcon}
                  rightButtonClassName={`${
                    selectedLayout?.captionStyle === CaptionStyle.UNDOCKED
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  isReadOnly={false}
                  footerText={''}
                  onSelection={(selectedSide) => updateActiveLayout('captionStyle', selectedSide)}
                  leftAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.caption-style-docked',
                  )}
                  rightAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.caption-style-docked',
                  )}
                />
              </div>
            </div>
          </div>

          <hr className="control-panel-section-divider" />
          <div className="captioning-settings-section">
            <div className="captioning-settings-position">
              <div className="captioning-settings-title">
                {t('producer.control-panel.captioning.text-options-title')}
              </div>
              <div className="level level-left">
                <div className="captioning-settings-section-color-wrapper">
                  <button
                    className={`captioning-settings-section-color-button`}
                    onClick={() =>
                      updateActiveLayout('captionTheme', CaptionThemes.BLACKBACKGROUNDWHITETEXT)
                    }
                    disabled={isReadOnly}
                    aria-label={t(
                      'producer.control-panel.captioning.accessibility.color-black-white-text',
                    )}
                  >
                    <span
                      className={`${
                        selectedLayout?.captionTheme === CaptionThemes.BLACKBACKGROUNDWHITETEXT
                          ? 'captioning-settings-section-color-button-selected'
                          : ''
                      }`}
                    ></span>
                    <span className="captioning-settings-section-color-button-text">Aa</span>
                  </button>
                  <button
                    className={`captioning-settings-section-color-button`}
                    onClick={() =>
                      updateActiveLayout('captionTheme', CaptionThemes.BLACKBACKGROUNDYELLOWTEXT)
                    }
                    disabled={isReadOnly}
                    aria-label={t(
                      'producer.control-panel.captioning.accessibility.color-black-yellow-text',
                    )}
                  >
                    <span
                      className={`${
                        selectedLayout?.captionTheme === CaptionThemes.BLACKBACKGROUNDYELLOWTEXT
                          ? 'captioning-settings-section-color-button-selected'
                          : ''
                      }`}
                    ></span>
                    <span className="captioning-settings-section-color-button-text captioning-settings-section-color-yellow-black">
                      Aa
                    </span>
                  </button>
                  <div className="captioning-settings-section-color-footer">
                    {t('producer.control-panel.captioning.text-options-colour')}
                  </div>
                </div>
                {/* <ToggleButton
                  leftText="s"
                  leftButtonClassName={`${
                    selectedLayout?.captionFontSize === CaptionFontSizes.SMALL
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  leftContentClassName="captioning-section-size-button-left-text"
                  leftValue={CaptionFontSizes.SMALL}
                  rightText="L"
                  rightButtonClassName={`${
                    selectedLayout?.captionFontSize === CaptionFontSizes.LARGE
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  rightContentClassName="captioning-section-size-button-right-text"
                  rightValue={CaptionFontSizes.LARGE}
                  footerText={t('producer.control-panel.captioning.text-options-size')}
                  isReadOnly={isReadOnly}
                  onSelection={(selectedSide) =>
                    updateActiveLayout('captionFontSize', selectedSide)
                  }
                  leftAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.font-small',
                  )}
                  rightAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.font-large',
                  )}
                /> */}
                <ToggleButton
                  leftImage={TextTwoLinesIcon}
                  leftButtonClassName={`${
                    selectedLayout?.captionLinesOfText === 2
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  leftValue={2}
                  rightValue={3}
                  rightImage={TextThreeLinesIcon}
                  rightButtonClassName={`${
                    selectedLayout?.captionLinesOfText === 3
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  footerText={t('producer.control-panel.captioning.text-options-lines')}
                  isReadOnly={isReadOnly}
                  onSelection={(selectedSide) =>
                    updateActiveLayout('captionLinesOfText', selectedSide)
                  }
                  leftAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.lines-two',
                  )}
                  rightAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.lines-three',
                  )}
                />
              </div>
            </div>
          </div>
          <div className="captioning-settings-position">
            <div className="level level-left font-family has-margin-bottom">
              <Dropdown
                value={selectedLayout?.captionFontFamily || 'Verdana'}
                options={fontFamilies}
                onChange={(data) => updateActiveLayout('captionFontFamily', data.value)}
                placeholderKey="producer.control-panel.captioning.font-family-placeholder"
                errorType={undefined}
                errorMessageKey="producer.control-panel.captioning.font-family-missing-value"
                readonly={false}
              />
              <div className="control-panel-toggle-button-footer dropdown-footer">Font</div>
            </div>
          </div>

          {/* <hr className="control-panel-section-divider" />

          <div className="captioning-settings-section">
            <div className="captioning-settings-position captioning-settings-position-block-with-title background-opacity">
              <div className="captioning-settings-title">
                {t('producer.control-panel.captioning.background-options-title')}
              </div>
              <div className="level level-left">
                <ToggleButton
                  leftText="100"
                  leftButtonClassName={`captioning-settings-opacity-button ${
                    selectedLayout?.captionBackgroundOpacity === 1
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  leftContentClassName="captioning-settings-opacity-button-text"
                  leftValue={1}
                  leftAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.opacity-solid',
                  )}
                  hasMiddleButton={true}
                  middleText="75"
                  middleValue={2}
                  middleContentClassName="captioning-settings-opacity-button-text"
                  middleAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.opacity-quarter',
                  )}
                  middleButtonClassName={`captioning-settings-opacity-button ${
                    selectedLayout?.captionBackgroundOpacity === 2
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  rightText="50"
                  rightButtonClassName={`captioning-settings-opacity-button ${
                    selectedLayout?.captionBackgroundOpacity === 3
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  rightValue={3}
                  rightContentClassName="captioning-settings-opacity-button-text"
                  rightAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.opacity-semi',
                  )}
                  footerText={t('producer.control-panel.captioning.background-options-opacity')}
                  isReadOnly={isReadOnly}
                  onSelection={(selectedSide) =>
                    updateActiveLayout('captionBackgroundOpacity', selectedSide)
                  }
                />
              </div>
            </div>
            <div className="captioning-settings-position captioning-settings-position-block-with-title">
              <div className="captioning-settings-title">
                {t('producer.control-panel.captioning.layout-title')}
              </div>
              <div className="level level-left">
                <ToggleButton
                  leftImage={FullScreenStreamIcon}
                  leftButtonClassName={`${
                    selectedLayout?.streamSize === StreamSizes.FULLSCREEN
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  leftValue={StreamSizes.FULLSCREEN}
                  rightValue={StreamSizes.SCALED}
                  rightImage={ScaledStreamIcon}
                  rightButtonClassName={`${
                    selectedLayout?.streamSize === StreamSizes.SCALED
                      ? 'control-panel-button-selected-button'
                      : null
                  }`}
                  footerText={t('producer.control-panel.captioning.stream-size')}
                  isReadOnly={isReadOnly}
                  onSelection={(selectedSide) => updateActiveLayout('streamSize', selectedSide)}
                  leftAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.layout-screen-full',
                  )}
                  rightAccessibilityName={t(
                    'producer.control-panel.captioning.accessibility.layout-screen-scaled',
                  )}
                />
              </div>
            </div>
          </div> */}
        </>
      )}

      <hr className="control-panel-section-divider" />

      <div className="captioning-settings-section level">
        <div className="captioning-settings-title">
          <p className="captioning-settings-title-text">
            {t('producer.control-panel.captioning.caption-overlay-title')}
          </p>
          <p className="captioning-settings-section-content-value">
            <a className="link" href={getStreamCastUrl()}>
              {t('producer.control-panel.captioning.caption-overlay-link')}
            </a>
            <img
              src={CopyIcon}
              alt="download"
              className="captioning-settings-section-download-image"
            />
          </p>
          <br />
          <p className="captioning-settings-section-content-value">
            <button className="link" onClick={() => setShowCaptionOverlayInstructions(true)}>
              {t('producer.control-panel.captioning.caption-overlay-instructions-link')}
            </button>
            <img
              src={PageIcon}
              alt="Instructions"
              className="captioning-settings-section-download-image"
            />
          </p>
        </div>
      </div>

      <hr className="control-panel-section-divider" />

      <div className="captioning-settings-section level">
        <div className="captioning-settings-title">
          <p className="captioning-settings-title-text">
            {t('producer.control-panel.captioning.stream-text-title')}
          </p>
          <a
            href={`${env.streamTextPlayerUrl}?event=${getStreamTextEventName()}`}
            rel="noopener noreferrer"
            target="_blank"
            className="captioning-settings-section-content-value"
          >
            {getStreamTextEventName()}
          </a>
        </div>
        {projectDetails?.streamTextEventName || projectDetails?.activeLayout?.hasDemoCaptions ? (
          <div className="level-right">
            <button
              onClick={() =>
                copyToClipboard(`${env.streamTextPlayerUrl}?event=${getStreamTextEventName()}`)
              }
              className="captioning-settings-section-copy-button"
            >
              {t('producer.control-panel.captioning.copy-text')}{' '}
              <img
                src={CopyIcon}
                alt="clipboard copy"
                className="captioning-settings-section-copy-image"
              />
            </button>
          </div>
        ) : null}
      </div>

      {projectDetails?.activeLayout?.hasCaption && projectDetails.streamTextEventName !== '' && (
        <>
          <hr className="control-panel-section-divider" />

          <div className="captioning-settings-section level">
            <div className="captioning-settings-title">
              <p className="captioning-settings-title-text">
                {t('producer.control-panel.captioning.qr-code')}
              </p>
              <p className="captioning-settings-section-content-value">
                <img src={getStreamTextQrCode()} className="qr-code" alt="" />
              </p>
            </div>
            <div className="level-right">
              <a href={getStreamTextQrCode()} className="captioning-settings-section-copy-button">
                {t('producer.control-panel.signing.download-text')}{' '}
                <img
                  src={CopyIcon}
                  alt="download"
                  className="captioning-settings-section-copy-image"
                />
              </a>
            </div>
          </div>
        </>
      )}

      {isModalOpen && (
        <InviteProjectUserModal
          isOpen={isModalOpen}
          contentLabel="Invite Captioner Modal"
          selectedProjectUser={selectedProjectUser}
          userType={UserTypes.CAPTIONER}
          handleCloseModal={() => {
            setIsModalOpen(false);
          }}
          turnFeatureOn={() => updateAssetDetails(true)}
        />
      )}
    </div>
  );
};

export default CaptioningPanel;
