import React, { BaseSyntheticEvent, useRef, useState } from 'react';
import { useMutate } from 'restful-react';
import { Dropdown } from '../../FormComponents/Dropdown/Dropdown';
import { TextInput } from '../../FormComponents/TextInput/TextInput';
import { BaseModal } from '../BaseModal/BaseModal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './ProjectDetailsModal.scss';
import { Button } from '../../FormComponents/Buttons/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ValidationError } from '../../FormComponents/ValidationError/ValidationError';
import { durationInMinutes, timezones } from '../../../common/constants';
import history from '../../../tools/history';
import {
  formatDate,
  getBaseUtcOffset,
  getTimezoneName,
  isDaylightSavings,
  isValidTime,
  isValidTimeZone,
  mergeDateAndTime,
} from '../../../helpers/dateFormatHelper/dateFormatHelper';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { ProjectDetail } from '../../../types/ProjectDetail';
import { errorMessageToTranslationKey } from '../../../helpers/errorMessageHelper/errorMessageHelper';
import moment from 'moment';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

type Props = {
  isOpen: boolean;
  contentLabel: string;
  projectDetails?: ProjectDetail | null;
  handleCloseModal: () => void;
};

type CreateProjectFormData = {
  id: string;
  name: string;
  timeZone: string;
  date: Date | string;
  time: string;
  duration: string;
  resolution: string;
};

export const ProjectDetailsModal = (props: Props) => {
  const date = props.projectDetails?.startDateTimeLocal || new Date();
  const time = props.projectDetails?.startTimeLocal || '';
  const defaultValues: CreateProjectFormData = {
    id: props.projectDetails?.id || '',
    name: props.projectDetails?.name || '',
    date: date,
    time: time,
    timeZone: getTimezoneName(date, time, props.projectDetails?.timeZone || 'GMT Standard Time'),
    duration: props.projectDetails?.duration.toString() || '60',
    resolution: props.projectDetails?.resolution.toString() || '1080',
  };

  const { t } = useTranslation();
  const { control, errors, handleSubmit, register } = useForm<CreateProjectFormData>({
    defaultValues,
  });
  const [loadingCreate, setLoadingCreate] = useState(false);
  const timezoneRef = useRef(defaultValues.timeZone);
  const dateRef = useRef(defaultValues.date);
  const timeRef = useRef(defaultValues.time);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessfulCreate = (body: CreateProjectFormData, data: any) => {
    if (data.statusCode === 201) {
      props.handleCloseModal();
      history.push('/producer/' + data.result);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSuccessfulUpdate = (body: CreateProjectFormData, data: any) => {
    if (data.statusCode === 200) {
      props.handleCloseModal();
    }
  };

  const { mutate: createProject, error: errorCreate } = useMutate<CreateProjectFormData>({
    verb: 'POST',
    path: '/projects',
    onMutate: onSuccessfulCreate,
  });

  const {
    mutate: updateProject,
    loading: loadingUpdate,
    error: errorUpdate,
  } = useMutate<CreateProjectFormData>({
    verb: 'PUT',
    path: '/projects',
    onMutate: onSuccessfulUpdate,
  });

  const onSubmit: SubmitHandler<CreateProjectFormData> = (
    data: CreateProjectFormData,
    event?: BaseSyntheticEvent,
  ) => {
    setLoadingCreate(true);
    event?.preventDefault();
    data.date = moment(data.date).format('YYYY-MM-DD');
    data.resolution = '1080';

    return props.projectDetails?.id ? updateProject(data) : createProject(data);
  };

  const getDate = (): string =>
    mergeDateAndTime(formatDate(moment(dateRef.current).toDate()), timeRef.current);

  const getTimezones = () => {
    return (
      timezones?.filter(
        (timezone) =>
          timezone.isDaylightSavings ===
          isDaylightSavings(getDate(), getBaseUtcOffset('GMT Standard Time')),
      ) || []
    );
  };

  const changeTimezone = (): void => {
    const date = getDate();

    if (
      isDaylightSavings(date, getBaseUtcOffset(getTimezoneName(date, time, 'GMT Standard Time')))
    ) {
      timezoneRef.current = timezoneRef.current.replace('Standard', 'Summer');
    } else {
      timezoneRef.current = timezoneRef.current.replace('Summer', 'Standard');
    }

    control.setValue('timeZone', '');
    control.setValue('timeZone', timezoneRef.current);
  };

  if (errorCreate) {
    return <ErrorMessage message={t(errorMessageToTranslationKey(errorCreate))} />;
  }

  if (errorUpdate) {
    return <ErrorMessage message={t(errorMessageToTranslationKey(errorUpdate))} />;
  }

  return (
    <BaseModal
      isOpen={props.isOpen}
      contentLabel={props.contentLabel}
      handleCloseModal={props.handleCloseModal}
      ariaHideApp={true}
      title={'create-project.modal-title'}
    >
      <form className="create-project-form" onSubmit={handleSubmit(onSubmit)}>
        <input
          name="id"
          defaultValue={defaultValues.id}
          type="hidden"
          ref={() => register({ name: 'id' })}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: true, maxLength: 150 }}
          render={({ onChange, value }) => (
            <TextInput
              placeholder="create-project.project-title-placeholder"
              value={value}
              onChange={onChange}
              onAfterChange={null}
              errorType={errors.name}
              errorMessageKey={
                value.length <= 150
                  ? 'create-project.missing-title'
                  : 'create-project.title-max-length'
              }
            />
          )}
        />
        <div className="columns">
          <div className="column">
            <ValidationError
              errorType={errors.date}
              errorMessageKey="create-project.missing-date"
            />
            <Controller
              name="date"
              control={control}
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Calendar
                  onChange={(data) => {
                    dateRef.current = formatDate(data as Date);
                    onChange(data);
                    changeTimezone();
                  }}
                  minDate={new Date()}
                  minDetail="year"
                  next2Label={null}
                  prev2Label={null}
                  value={value ? new Date(value) : new Date()}
                />
              )}
            />
          </div>
          <div className="column">
            <ValidationError errorType={errors.time} errorMessageKey={errors.time?.message ?? ''} />
            <Controller
              name="time"
              control={control}
              rules={{
                required: 'create-project.missing-time',
                validate: (value) => isValidTime(value, getDate(), timezoneRef.current),
              }}
              render={({ onChange, value }) => (
                <input
                  type="time"
                  onChange={(data) => {
                    timeRef.current = data.currentTarget.value;
                    onChange(data);
                    changeTimezone();
                  }}
                  className={'input timepicker' + (errors.time ? ' is-danger' : '')}
                  id="timepicker"
                  aria-label="start time"
                  name="timepicker"
                  value={value}
                ></input>
              )}
            />
            <Controller
              control={control}
              name="timeZone"
              className="mb-3"
              rules={{ required: true, validate: (value) => isValidTimeZone(value) }}
              render={({ onChange, value }) => (
                <Dropdown
                  value={value || getTimezoneName(getDate(), timeRef.current, value)}
                  options={getTimezones()}
                  onChange={(data) => {
                    timezoneRef.current = data?.value;
                    onChange(data?.value);
                  }}
                  placeholderKey="create-project.timezone-placeholder"
                  errorType={errors.timeZone}
                  errorMessageKey="create-project.missing-timezone"
                />
              )}
            />
            <Controller
              control={control}
              name="duration"
              className="mb-3"
              rules={{ required: true, min: durationInMinutes[0].value }}
              render={({ onChange, value }) => (
                <Dropdown
                  value={value || '60'}
                  options={durationInMinutes}
                  onChange={(data) => onChange(data?.value)}
                  placeholderKey="create-project.duration-placeholder"
                  errorType={errors.duration}
                  errorMessageKey="create-project.missing-duration"
                />
              )}
            />
            {/* <Controller
              control={control}
              name="resolution"
              className="mb-3"
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <Dropdown
                  value={value || 1080}
                  options={[
                    { value: '720', text: '720p' },
                    { value: '1080', text: '1080p' },
                  ]}
                  onChange={(data) => onChange(data?.value)}
                  placeholderKey="create-project.resolution-placeholder"
                  errorType={errors.resolution}
                  errorMessageKey="create-project.missing-resolution"
                />
              )}
            /> */}
          </div>
        </div>
        <div className="shutoff-notice">
          <p>{t('create-project.auto-shutoff-1')}</p>
          <br />
          <p>{t('create-project.auto-shutoff-2')}</p>
        </div>
        {props.projectDetails?.id ? (
          <>
            {!loadingUpdate ? (
              <Button
                translationKey="create-project.update"
                variant="primary"
                color="blue"
                onClick={() => null}
                type="submit"
                disabled={loadingUpdate}
              />
            ) : (
              <LoadingSpinner />
            )}
          </>
        ) : (
          <>
            {!loadingCreate ? (
              <Button
                translationKey="create-project.submit"
                variant="primary"
                color="blue"
                onClick={() => null}
                type="submit"
                disabled={loadingCreate}
              />
            ) : (
              <LoadingSpinner />
            )}
          </>
        )}
      </form>
    </BaseModal>
  );
};
