import React from 'react';
import { useTranslation } from 'react-i18next';
import '../SharedControlPanel.scss';
import './StreamSourcePanel.scss';

import CopyIcon from '../../../../../images/copy-icon.png';
// import { Dropdown } from '../../../../FormComponents/Dropdown/Dropdown';
// import useProjectDetails from '../../../../../hooks/useProjectDetails';
// import { regions } from '../../../../../common/constants';
import { Layout } from '../../../../../types/Layout';

type StreamSourcePanelProps = {
  sourceUrl: string | undefined;
  sourceKey: string | undefined;
  selectedLayout: Layout | undefined | null;
};

const StreamSourcePanel = ({ sourceUrl, sourceKey, selectedLayout }: StreamSourcePanelProps) => {
  const { t } = useTranslation();
  // const { projectDetails, updateActiveLayout } = useProjectDetails();

  const copyToClipboard = (copyValue: string) => {
    navigator.clipboard.writeText(copyValue);
  };

  return (
    <div className="stream-source-panel">
      <div className="stream-source-panel-section level">
        <div className="stream-source-panel-section-content">
          <p className="stream-source-panel-section-content-title">
            {t('producer.control-panel.stream-source.stream-key')}
          </p>
          <p className="stream-source-panel-section-content-value">{sourceKey}</p>
        </div>
        {sourceKey ? (
          <div className="level-right">
            <button
              onClick={() => copyToClipboard(sourceKey)}
              className="stream-source-panel-copy-button"
            >
              {t('producer.control-panel.stream-source.copy-text')}{' '}
              <img src={CopyIcon} alt="clipboard copy" className="stream-source-panel-copy-image" />
            </button>
          </div>
        ) : null}
      </div>
      <hr className="control-panel-section-divider" />
      <div className="stream-source-panel-section level">
        <div className="stream-source-panel-section-content">
          <p className="stream-source-panel-section-content-title">
            {t('producer.control-panel.stream-source.stream-url')}
          </p>
          <p className="stream-source-panel-section-content-value">{sourceUrl}</p>
        </div>
        {sourceUrl ? (
          <div className="level-right">
            <button
              onClick={() => copyToClipboard(sourceUrl)}
              className="stream-source-panel-copy-button"
            >
              {t('producer.control-panel.stream-source.copy-text')}{' '}
              <img src={CopyIcon} alt="clipboard copy" className="stream-source-panel-copy-image" />
            </button>
          </div>
        ) : null}
      </div>
      <hr className="control-panel-section-divider" />
      {/* <div className="stream-source-panel-section level">
        <div className="stream-source-panel-section-content">
          <p className="stream-source-panel-section-content-title">
            {t('producer.control-panel.stream-source.region')}
          </p>
          <Dropdown
            value={selectedLayout?.region || 'REGION_EU_WEST_1'}
            options={regions}
            onChange={(data) => updateActiveLayout('region', data.value)}
            placeholderKey="producer.control-panel.stream-source.region-placeholder"
            errorType={undefined}
            errorMessageKey="producer.control-panel.stream-source.region-missing-value"
            readonly={projectDetails?.broadcastStartedOnUtc !== undefined}
          />
        </div>
      </div> */}
      {/* <div className="stream-source-panel-section-footer has-no-margin-top">
        <p>
          <span className="captioning-panel-section-footer-title">
            {t('producer.control-panel.captioning.note')}{' '}
          </span>
          <span className="captioning-panel-section-footer-text">
            {t('producer.control-panel.stream-source.region-note-text')}
          </span>
        </p>
      </div> */}
    </div>
  );
};

export default StreamSourcePanel;
