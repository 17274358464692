import React, { FunctionComponent } from 'react';

import './ProjectMainSection.scss';

import MainSectionVideo from './MainSectionVideo/MainSectionVideo';
import MainSectionFooter from './MainSectionFooter/MainSectionFooter';
import { ProjectDetail } from '../../types/ProjectDetail';
import { Peer, Peers } from '../../types/SignalR';
import MainSectionHeader from './MainSectionHeader/MainSectionHeader';
// import { UserTypes } from '../../types/ProjectUser';
// import UserCamera from '../SignerComponents/UserCamera/UserCamera';
// import useRemoteStreams from '../../hooks/useRemoteStream';
// import usePeer from '../../hooks/usePeer';

type MainSectionProps = {
  isProducer: boolean;
  projectDetails: ProjectDetail | null;
  token?: string;
  sendRequestControlCallback: () => void;
  sendTakeControlCallback: () => void;
  sendGiveControlCallback: () => void;
  getPeerInfoCallback: (peerId: string) => Peer | null;
  peerRequestingControl: Peer | null;
  connectedPeers: Peers;
  sendRefreshLiveVideoCallback?: () => void;
  triggerRefreshLiveVideo: boolean;
  isAwaitingApiSaveToComplete: boolean;
  isUserActiveCallback: (userId: string) => boolean;
  isUserLiveCallback: (userId: string) => boolean;
  myConnectionIsLive: boolean;
  sendReloadProxyVideo: () => void;
  sendStopSignerVideoCallback?: () => void;
  sendStartSignerVideoCallback?: () => void;
};

const ProjectMainSection: FunctionComponent<MainSectionProps> = (props) => {
  // const peerId = `Producer-${props.projectDetails?.id}`;
  // const { remoteStreams, addRemoteStream, removeRemoteStream } = useRemoteStreams();
  // const {
  //   callPeer,
  //   removeRemoteCallsToOtherSigners,
  //   videoEnabled,
  //   enableVideo,
  //   disableVideo,
  //   createFakeStream,
  // } = usePeer(
  //   peerId,
  //   addRemoteStream,
  //   removeRemoteStream,
  //   props.projectDetails?.iceServers ?? [],
  //   false,
  // );

  // useEffect(() => {
  //   const call = (remoteId: string) => {
  //     callPeer(remoteId, createFakeStream());
  //   };

  //   for (const peer of props.connectedPeers) {
  //     if (peer.userType === UserTypes.SIGNER && peer.peerId !== peerId) {
  //       call(peer.peerId);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.connectedPeers]);

  // useEffect(() => {
  //   // clean up Peer Calls if no other 'signers' are connected.
  //   // this approach will only works for 2 signers, will need refactoring if we increase the number of signers
  //   const otherSignersConnected = props.connectedPeers.find(
  //     (p) =>
  //       (p.userType === UserTypes.SIGNER || p.userType === UserTypes.PRODUCER) &&
  //       p.peerId !== peerId,
  //   );
  //   if (remoteStreams.length > 0 && !otherSignersConnected) {
  //     // do not close call to proxy, just close calls to other signers.
  //     removeRemoteCallsToOtherSigners();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.connectedPeers]);

  // const cameraColumnCssClassName = (): string => {
  //   return `column${remoteStreams.length > 1 ? ' is-4-signers' : ''}`;
  // };

  // const buildCamera = useCallback(
  //   (index: number): unknown => {
  //     const streams = remoteStreams.filter(
  //       (x) => props.getPeerInfoCallback(x.peerId)?.userType === UserTypes.SIGNER,
  //     );

  //     if (index >= streams.length) {
  //       return;
  //     }
  //     const stream = streams[index];

  //     if (!props.getPeerInfoCallback(stream.peerId)) {
  //       return;
  //     }

  //     if (stream.stream.active) {
  //       return (
  //         <UserCamera
  //           key={stream.peerId}
  //           stream={stream.stream}
  //           peerId={stream.peerId}
  //           getPeerInfoCallback={props.getPeerInfoCallback}
  //           userId={props.getPeerInfoCallback(stream.peerId)?.userId ?? ''}
  //           isUserActiveCallback={props.isUserActiveCallback}
  //           isUserLiveCallback={props.isUserLiveCallback}
  //           colorIdNumber={index + 1}
  //           myCamera={false}
  //           videoEnabled={videoEnabled}
  //           enableVideo={enableVideo}
  //           disableVideo={disableVideo}
  //           isProducer={true}
  //         />
  //       );
  //     }
  //   },
  //   [remoteStreams, enableVideo, disableVideo, props, videoEnabled],
  // );

  return (
    <div className="project-main-section">
      <MainSectionHeader {...props} />
      <div className="columns signer-main-section">
        <MainSectionVideo
          projectDetails={props.projectDetails}
          triggerRefreshLiveVideo={props.triggerRefreshLiveVideo}
          isAwaitingApiSaveToComplete={props.isAwaitingApiSaveToComplete}
          userType={props.getPeerInfoCallback(props.token || '')?.userType}
        />
      </div>
      {/* {props.projectDetails?.activeLayout?.hasSigner && props.isProducer && (
        <div className="columns signer-main-section">
          <div className="column">
            <div className="columns signers">
              <div className={cameraColumnCssClassName()}>
                {buildCamera(0)}
                {buildCamera(1)}
                {buildCamera(2)}
                {buildCamera(3)}
              </div>
            </div>
          </div>
        </div>
      )} */}
      <MainSectionFooter
        {...props}
        sendRefreshLiveVideoCallback={props.sendRefreshLiveVideoCallback}
        sendTakeControlCallback={props.sendTakeControlCallback}
      />
    </div>
  );
};

export default ProjectMainSection;
